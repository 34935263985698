<template>
  <ModuleComponent
    ref="moduleComponent"
    name="buyerComments"
    @changed="item = $event"
    :module-data="moduleData"
    :hide-filters="hideFilters"
    :no-bread-crumb="noBreadCrumb"
  >
    <template v-slot:tableFilters="{ filters }">
      <b-container fluid>
        <b-row>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.BUYERS.D')">
              <v-select2
                v-model="filters.objectId.value"
                endPoint="accounts"
                :placeholder="t('GLOBAL.BUYERS.D')"
                :filters="{
                  accountTypeId: { value: Constants.AccountTypes.Buyer }
                }"
                sort="name"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-form-input
                name="name"
                v-model="filters.name.value"
                :placeholder="t('GLOBAL.NAME')"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.EMAIL')">
              <b-form-input
                v-model="filters.email.value"
                :placeholder="t('GLOBAL.EMAIL')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-slot:customColumn_active="{ rowItem }">
      <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
    </template>
    <template v-slot:form="{ item }">
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" sm="12">
            <b-form-group :label="t('GLOBAL.BUYERS.D')">
              <v-select2
                v-model="item.objectId"
                :text="
                  item.buyer && item.buyer.account
                    ? item.buyer.account.name
                    : ''
                "
                endPoint="accounts"
                :placeholder="t('GLOBAL.BUYERS.D')"
                :filters="{
                  accountTypeId: { value: Constants.AccountTypes.Buyer }
                }"
                sort="name"
              ></v-select2>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" sm="6">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-input-group>
                <b-input-group-prepend
                  ><span class="input-group-text"
                    ><i class="fa fa-user fa-lg"></i></span
                ></b-input-group-prepend>
                <b-form-input v-model="item.name"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" sm="6">
            <b-form-group :label="t('GLOBAL.EMAIL')">
              <b-input-group prepend="@">
                <b-form-input
                  :name="t('GLOBAL.EMAIL')"
                  v-model="item.email"
                  type="email"
                  :placeholder="t('GLOBAL.EMAIL')"
                  v-validate.continues="'required|email'"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" sm="12">
            <b-form-group :label="t('GLOBAL.TITLE')">
              <b-form-input v-model="item.title"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="12" sm="12">
            <b-form-group :label="t('GLOBAL.COMMENT')">
              <b-form-textarea v-model="item.text"></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2" sm="2">
            <b-form-group :label="t('GLOBAL.HELPFUL')">
              <b-input-group>
                <b-input-group-prepend
                  ><span class="input-group-text"
                    ><i class="fa fa-thumbs-up fa-lg"></i></span
                ></b-input-group-prepend>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="item.helpful"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2" sm="2">
            <b-form-group :label="t('GLOBAL.UNHELPFUL')">
              <b-input-group>
                <b-input-group-prepend
                  ><span class="input-group-text"
                    ><i class="fa fa-thumbs-down fa-lg"></i></span
                ></b-input-group-prepend>
                <b-form-input
                  type="number"
                  min="0"
                  v-model="item.unhelpful"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" sm="6">
            <b-form-group
              :label="t('GLOBAL.RATING') + ' [' + item.rating + ']'"
            >
              <v-rating v-model="item.rating"></v-rating>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3" sm="3">
            <b-form-checkbox
              size="lg"
              switch
              v-model="item.active"
              name="active"
              >{{ t("GLOBAL.ACTIVE") }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </ModuleComponent>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Harvest
export default {
  name: "buyerComments",
  data() {
    return {
      item: {},
      moduleData: {
        name: "buyerComments",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: true,
          graphqlQuery: `
							id,
	                        name,
	                        email,
	                        text,
	                        title,
	                        rating,
	                        helpful,
	                        unhelpful,
	                        active,
	                        date,
	                        buyer{
	                            account{
	                                name
	                            }
	                        }
                        `,

          headers: [
            { text: "ID", value: "id", width: "50" },
            {
              text: this.t("GLOBAL.BUYERS.D"),
              sortable: true,
              value: "buyer.account.name"
            },
            { text: this.t("GLOBAL.NAME"), sortable: true, value: "name" },
            { text: this.t("GLOBAL.EMAIL"), sortable: true, value: "email" },
            { text: this.t("GLOBAL.TITLE"), sortable: true, value: "title" },
            {
              text: this.t("GLOBAL.HELPFUL"),
              sortable: true,
              value: "helpful"
            },
            {
              text: this.t("GLOBAL.UNHELPFUL"),
              sortable: true,
              value: "unhelpful"
            },
            { text: this.t("GLOBAL.RATING"), sortable: true, value: "rating" },
            { text: this.t("GLOBAL.DATE"), sortable: true, value: "date" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "active",
              width: "60",
              align: "center"
            }
          ],
          customColumns: ["active"],
          filters: {
            name: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            email: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            objectId: {
              type: "number",
              value: null
            }
          }
        },
        form: {
          fullScreen: true
        }
      }
    };
  },
  methods: {
    filterByBuyer(buyer) {
      this.moduleData.table.filters.objectId.value = buyer.id;
    }
  }
};
</script>
